import FilterGroup from "../../../../component/general/filter/FilterGroup";
import FilterItemSelect from "../../../../component/general/filter/FilterItemSelect";
import React from "react";

const ProductDisplayPageSizeOption = ({ pageSize=20, options=[20,50,100], onChange }) => {
    return (
        <FilterGroup name="Page Size">
            {options.map((item, index) => (
                <FilterItemSelect key={index} name={item} selected={pageSize===item} onSelectChange={() => onChange(item)} />
            ))}
        </FilterGroup>
    )
}

export default ProductDisplayPageSizeOption;
