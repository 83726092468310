import useWebProduct from "../../../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../../../res/product/productPurchaseInfo";
import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import ProductLine from "../../productLine/ProductLine";
import {range} from "../../../../../res/dataServices/range";
import SkeletonDiv from "../../../../../components/general/loading/SkeletonDiv";

const ProductDisplay = ({ product, getDisplayProduct}) => {

    const { webProduct } = useWebProduct({product});
    const displayProduct = getDisplayProduct ? getDisplayProduct(webProduct) :
        ProductPurchaseInfo.getGenericDisplayProduct(webProduct);

    return (
        <ProductLine product={displayProduct} />
    )
}

const trackListing = (loading, products) => {
    if (!loading && products && products.length > 0) {

        const items = products.map(product => ({
            item_id: product.id,
            item_name: product.name,
        }));

        ReactGA.event('view_item_list', {
            items: items
        });
    }
}

const ProductList = ({ products, loading, loadingSize = 10, children, getDisplayProduct }) => {

    useEffect(() => trackListing(loading, products), [loading, products]);

    if(loading) {
        return (
            <div>
                {range(loadingSize).map((e,i) =>
                    <SkeletonDiv
                        key={i}
                        style={{width: 700, maxWidth: "80vw", height: "60px", marginBottom: 30}}
                    />
                )}
                {children}
            </div>
        )
    }

    return (
        <div>
            {products && products.map((product) =>
                <ProductDisplay
                    key={product.id}
                    product={product}
                    getDisplayProduct={getDisplayProduct}
                />
            )}
        </div>
    )
}

export default ProductList;
