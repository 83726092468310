import {useDispatch, useSelector} from "react-redux";
import Popup from "../general/popup/Popup";
import "./error.css"
import {clearErrorReport} from "../../redux/slice/errorReportSlice";
import Dialog from "../../newStructure/component/general/dialog/Dialog";
import DialogTitle from "../../newStructure/component/general/dialog/DialogTitle";
import DialogButtonContainer from "../../newStructure/component/general/dialog/DialogButtonContainer";
import DialogButton, {DIALOG_BUTTON_TYPE} from "../../newStructure/component/general/dialog/DialogButton";
import DialogMessage from "../../newStructure/component/general/dialog/DialogMessage";

const ErrorReport = () => {

    const dispatch = useDispatch();
    const { toReport } = useSelector(
        state => state.session.errorReport
    )

    const reportCodes = Object.keys(toReport);
    const somethingToReport = reportCodes.length;

    const clearAReport = (code) => () => {
        dispatch(clearErrorReport({ code }))
    }

    if(somethingToReport) {

        const reportKey = reportCodes[0];
        const reportError = toReport[reportKey];
        return(
            <Popup>
                <Dialog>
                    <DialogTitle>Oh No! Something has gone wrong</DialogTitle>
                    <DialogMessage>It seems something has gone wrong. Please review the following information:</DialogMessage>
                    <DialogMessage>
                        <span style={{fontWeight: "bold"}}>
                            {reportError}
                        </span>
                    </DialogMessage>
                    <DialogMessage>
                        If you continue to experience difficulties, feel free to contact our support team for
                        assistance. Thank you for your understanding.
                    </DialogMessage>
                    <DialogButtonContainer>
                        <DialogButton
                            onClick={clearAReport(reportKey)}
                            type={DIALOG_BUTTON_TYPE.neutral}
                        >
                            OK
                        </DialogButton>
                    </DialogButtonContainer>
                </Dialog>
            </Popup>
        )
    }

}

export default ErrorReport;
