import FilterGroup from "../../../../component/general/filter/FilterGroup";
import FilterItemSelect from "../../../../component/general/filter/FilterItemSelect";
import React from "react";

const ProductDisplayLayoutOptions = ({layout, onChange}) => {

    return (
        <FilterGroup name="Product Layout">
            <FilterItemSelect name="Grid" selected={layout==="Grid"} onSelectChange={() => onChange("Grid")} />
            <FilterItemSelect name="List" selected={layout==="List"} onSelectChange={() => onChange("List")} />
        </FilterGroup>
    )
}

export default ProductDisplayLayoutOptions;
