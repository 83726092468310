import Dialog from "./Dialog";
import {Ring} from "@uiball/loaders";
import Popup from "../../../../components/general/popup/Popup";

const Wrapper = ({noPopup, children}) => {
    if(noPopup) return <>{children}</>
    return <Popup>{children}</Popup>
}

const DialogLoading = ({ title, message, loading, noPopup=false }) => {
    if(!loading) return null;

    return (
        <Wrapper noPopup={noPopup}>
            <Dialog>
                <div className="loading-dialog-content">
                    <Ring
                        size={50}
                        lineWeight={4}
                        speed={2}
                        color="var(--secondary)"
                    />
                    <div>
                        <p className="loading-dialog-title">{title}</p>
                        {message &&
                            <p>{message}</p>
                        }
                    </div>
                </div>
            </Dialog>
        </Wrapper>
    )
}

export default DialogLoading;
