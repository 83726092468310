import ProductList from "../productList/ProductList";
import ProductGrid from "../productGrid/ProductGrid";

const ProductDisplaySwitcher = ({ display, products, loading, loadingSize = 10, children, getDisplayProduct }) => {

    if(display === "List") {
        return (
            <ProductList
                products={products}
                loading={loading}
                loadingSize={loadingSize}
                getDisplayProduct={getDisplayProduct}>
                {children}
            </ProductList>
        )
    } else {
        return (
            <ProductGrid
                products={products}
                loading={loading}
                loadingSize={loadingSize}
                getDisplayProduct={getDisplayProduct}>
                {children}
            </ProductGrid>
        )
    }

}

export default ProductDisplaySwitcher;
