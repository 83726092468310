import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const businessLocationRequest = () => {

    const builder = {};

    const req = request.model(api.MODEL.BP_LOCATION)
        .expand("C_Location_ID", "C_SalesRegion_ID");

    const getSalesRegion = async(session, regionID) => {
        const req = request.model(api.MODEL.C_SALES_REGION)
            .expand("x_deliveryPreference")
            .filter(condition.eq("C_SalesRegion_ID", regionID))
            .buildRequest();

        const url = env.API_URL + req;
        const response = await HttpClient.client.get(url, api.auth(session));
        return response.data.records[0];
    }

    const attachSalesRegionToLocations = async(session, locations) => {
        const salesRegions = {};

        for(const location of locations) {
            const regionID = location["C_SalesRegion_ID"]
            if(regionID) {
                const region = salesRegions[regionID.id] ? salesRegions[regionID.id] :
                    await getSalesRegion(session, regionID.id);
                salesRegions[regionID.id] = region;
                location["C_SalesRegion_ID"] = region;
            }
        }
    }

    builder.sendIt = async (session) => {
        req.filter(condition.eq("C_BPartner_ID", session.bPartnerID))
        const url = env.API_URL + req.buildRequest();
        const response = await HttpClient.client.get(url, api.auth(session));
        const locations = response.data.records;
        await attachSalesRegionToLocations(session, locations);
        return locations;
    }

    return builder;

}

export default businessLocationRequest;
