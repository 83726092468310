import Popup from "../../../../components/general/popup/Popup";
import Dialog from "../../general/dialog/Dialog";
import DialogTitle from "../../general/dialog/DialogTitle";
import DialogButtonContainer from "../../general/dialog/DialogButtonContainer";
import DialogButton from "../../general/dialog/DialogButton";
import BannerDateSelector from "./BannerDateSelector";
import {useState} from "react";
import DialogScrollView from "../../general/dialog/DialogScrollView";
import './banner.css'
import LoadingSwitcher from "../../general/loading/LoadingSwitcher";
import DialogLoading from "../../general/dialog/DialogLoading";
import useAuthRequest from "../../../services/state/request/useAuthRequest";
import DialogMessage from "../../general/dialog/DialogMessage";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import {request} from "../../../../res/rest/restRequest";

const getRerunRequest = (copy, newDates) => {

    const StartDate = idempiereDate.getPostString(newDates.startDate);
    const EndDate = idempiereDate.getPostString(newDates.endDate);

    const newData = {
        C_BPartner_ID: copy.C_BPartner_ID.id,
        Description: copy.Description,
        M_Product_Category_ID: copy.M_Product_Category_ID.id,
        M_Product_ID: copy.M_Product_ID?.id ? copy.M_Product_ID.id : 0,
        NavigationURL: copy.NavigationURL,
        leaderboardImageUrl: copy.leaderboardImageUrl,
        mobileLeaderBoardImageUrl: copy.mobileLeaderBoardImageUrl,
        skyscraperImageUrl: copy.skyscraperImageUrl,
        StartDate,
        EndDate,
    }

    return request.model("x_Upsell_Product")
        .data(newData)
        .post
}

const formatDate = (date) => {
    if(!date) return "--";

    const jsDate = new Date(date);
    const day = String(jsDate.getDate()).padStart(2, '0');
    const month = String(jsDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = jsDate.getFullYear();
    return `${day}/${month}/${year}`
}

const RerunSetUp = ({ promotion, onBack, onCreate }) => {
    const start = formatDate(promotion.StartDate);
    const end = formatDate(promotion.EndDate);
    const catName = promotion.M_Product_Category_ID?.identifier;
    const categoryId = promotion.M_Product_Category_ID?.id;

    const [dates, setDates] = useState();

    const onRerunClick = () => {
        if(dates?.endDate) {
            onCreate(dates);
        }
    }

    const rerunButtonType = dates?.endDate ? 3 : 1;


    return (
        <Dialog>
            <DialogTitle>Re-Run Promotion</DialogTitle>
            <DialogScrollView className="promo-rerun-container">
                <p style={{marginTop: 8}} className="acct-line-h1">{catName}</p>
                <p style={{marginTop: 2}} className="acct-line-h1">{start} - {end}</p>
                <BannerDateSelector
                    categoryID={categoryId}
                    onChange={setDates}
                />
            </DialogScrollView>
            <DialogButtonContainer>
                <DialogButton type={1} onClick={onBack}>Back</DialogButton>
                <DialogButton type={rerunButtonType} onClick={onRerunClick}>Re-Run</DialogButton>
            </DialogButtonContainer>
        </Dialog>
    )
}

const CreatingPopup = () => {
    return (
        <DialogLoading
            loading={true}
            title="Re-Run Promotion"
            message="Creating promotion, please wait."
            noPopup={true}
        />
    )
}

const CompletedMessage = ({ onBack }) => {

    return (
        <Dialog>
            <DialogTitle>Re-Run Promotion</DialogTitle>
            <DialogMessage>Your new run of this promotion has been added to our system.</DialogMessage>
            <DialogButtonContainer>
                <DialogButton type={1} onClick={onBack}>Back</DialogButton>
            </DialogButtonContainer>
        </Dialog>
    )
}

const ReRunPromotionPopUp = ({ promotion, onBack }) => {

    const {
        loading,
        sendIt
    } = useAuthRequest();

    const [ completed, setCompleted ] = useState(false);

    const onCreate = (dates) => {
        sendIt(getRerunRequest(promotion, dates), () => setCompleted(true));
    }

    return (
        <Popup>
            <LoadingSwitcher loading={loading} altSkeleton={<CreatingPopup />}>
                {completed ?
                    <CompletedMessage onBack={onBack} /> :
                    <RerunSetUp
                        promotion={promotion}
                        onBack={onBack}
                        onCreate={onCreate}
                    />
                }
            </LoadingSwitcher>
        </Popup>
    )

}

export default ReRunPromotionPopUp;
