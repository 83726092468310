import useSolrProduct from "./useSolrProduct";
import productSearchParamsBuilder from "../../../logic/product/productSearchParams";
import FilterUrl from "../../../logic/filter/filterUrl";
import MapUtil from "../../../logic/collection/mapUtil";
import useValueChangeListener from "../general/useValueChangeListener";

const DEFAULT_PAGE_SIZE = 20;

const useCategoryPage = (pageNo, categoryID, filterParam, pageSize=DEFAULT_PAGE_SIZE) => {

    const {
        error,
        loading,
        products,
        productCount,
        getProducts,
    } = useSolrProduct();

    const getParams = () => {
        const filters = FilterUrl.getMap(filterParam);
        const builder = productSearchParamsBuilder()
            .pageSize(pageSize)
            .pageNo(pageNo)
            .categoryID(categoryID);

        if(filters) {
            MapUtil.forEach(filters, (k, v) => {
                v.forEach(fv => builder.filterAttribute(k, fv))
            })
        }

        return builder.getParams();
    }

    useValueChangeListener(() => getProducts(getParams()), [pageNo, categoryID, filterParam, pageSize], []);

    const pageCount = (Math.ceil(productCount / pageSize)) + 1

    return {
        error,
        loading,
        products,
        pageCount
    }

}

export default useCategoryPage;
