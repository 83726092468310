import MapUtil from "../collection/mapUtil";

const getAttributeMap = (attributes) => {
    const map = {};
    for(const attribute of attributes) {
        map[attribute.id] = attribute;
    }
    return map;
}

const compareFilterOptions = (thisOption, thatOption) => {
    const thisSeqNo = thisOption.seqNo ? thisOption.seqNo : 0;
    const thatSeqNo = thatOption.seqNo ? thatOption.seqNo : 0;

    if(thisSeqNo !== thatSeqNo) return thisSeqNo - thatSeqNo;

    const thisName = thisOption.name;
    const thatName = thatOption.name;

    return thisName.localeCompare(thatName);
}

const getValueMap = (attributeMap, values) => {
    const map = {};
    for(const value of values) {
        const attributeId = value.attributeID;
        if(!(attributeId in map)) {
            map[attributeId] = {
                attribute: attributeMap[attributeId],
                options: []
            };
        }
        map[attributeId].options.push(value);
    }

    for(const filter of Object.values(map)) {
        filter.options.sort(compareFilterOptions)
    }

    return map;
}

const parseMeasure = (str) => {
    let multiplier = 1;
    let value = 0;
    let unit = '';

    // Handle multiplier format (e.g., "2x155g")
    if (str.includes('x')) {
        const [mult, rest] = str.split('x');
        multiplier = parseFloat(mult);
        str = rest;
    }

    // Extract numeric value and unit
    const numMatch = str.match(/[\d.]+/);
    const unitMatch = str.match(/[a-zA-Z]+/);

    if (numMatch) value = parseFloat(numMatch[0]);
    if (unitMatch) unit = unitMatch[0].toLowerCase();

    const unitVal = (() => {
        switch (unit) {
            case 'ml':  return 1;
            case 'cl':  return 2;
            case 'ltr': return 3;
            case 'g':   return 4;
            case 'kg':  return 5;
            default: return 20;
        }
    })();

    return {
        multiplier,
        value,
        unitVal
    }
}

const compareSize = (aName,bName) => {
    const a = parseMeasure(aName);
    const b = parseMeasure(bName);

    if (a.unitVal !== b.unitVal) {
        return a.unitVal - b.unitVal;
    }
    if (a.value !== b.value) {
        return a.value - b.value;
    }
    return a.multiplier - b.multiplier;
}

const removeUnitDuplicates = (units) => {

    const vals = {};

    for(const unit of units) {
        const k = unit.name;
        if (k in vals) {
            vals[k].referenceIDs = [...vals[k].referenceIDs, ...unit.referenceIDs];
        } else {
            vals[k] = {...unit};
        }
    }

    return Object.values(vals);
}

const sortUnitSize = (valueMap) => {
    if(!valueMap) return;

    const unitSize = MapUtil.find(valueMap, (k,v) => v.attribute.name === "Unit-Size")
    if(!unitSize) return;

    const [k,v] = unitSize;
    console.log(k);

    const options = removeUnitDuplicates(v.options);
    options.sort((a,b) => compareSize(a.name, b.name));

    v.options = options;

    // console.log(v.options.map(a => a.name).join("', '"));

    // console.log("sorting")

}

const filterMap = (attributes, values) => {
    if(!attributes || ! values) return {}

    const attributeMap = getAttributeMap(attributes);
    const valueMap = getValueMap(attributeMap, values);
    sortUnitSize(valueMap);

    return valueMap;
}

export default filterMap;
