import PageLayout from "../../../../components/page/PageLayout";
import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import useUsualsPage from "../hook/useUsualsPage";
import {Breadcrumb, BreadcrumbNavigation} from "../../../component/navigation/Breadcrumb";
import ProductLayout from "../../../component/product/ProductLayout";
import useWebProduct from "../../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../../res/product/productPurchaseInfo";
import ProductLine from "../../product/productLine/ProductLine";
import FilterContainer from "../../../component/general/filter/FilterContainer";
import OrderEntryNav from "../component/orderEntryNav/OrderEntryNav";
import Pagination from "../../../component/navigation/Pagination";
import {Button} from "../../../../components/general/input/Button";
import ProductDisplayOptions from "../../product/productDisplay/productDisplayOptions/ProductDisplayOptions";
import ProductGrid from "../../product/productDisplay/productGrid/ProductGrid";

const ProductDisplay = ({ product }) => {

    const { webProduct } = useWebProduct({product});
    const displayProduct = ProductPurchaseInfo.getGenericDisplayProduct(webProduct);

    return (
        <ProductLine product={displayProduct} />
    )

}

const ProductList = ({ products, loading }) => {

    if(loading) {
        return <p></p>
    }

    return (
        <div>
            {products?.map((p,i) =>
                <ProductDisplay product={p} key={i} />
            )}
        </div>
    )
}

const NoProducts = () => {

    const navigate = useNavigate();

    return (
        <div className="page-content page-min-height" style={{textAlign: "left"}}>
            <p className="page-h2" style={{marginTop: 30}}>No Products</p>
            <p style={{maxWidth: 600, color: "#5a5a5d", fontWeight: 500}}>
                This page displays items you've ordered within the last few months for quick reordering.
                As we don't have any orders within that timeframe for your account,
                you'll need to browse our product catalog below to place a new order.
                Once you place an order, your recent purchases will be displayed here for easy reordering.</p>
            <Button
                onClick={() => navigate("/promotion")}
                style={{maxWidth: 600}}
            >
                View Our Latest Deals
            </Button>
        </div>
    )
}


const OrderEntryContent = () => {

    const [params, setParams] = useSearchParams();
    const pageNo = params.get("pageNo");
    const category = params.get("category");
    const [{ layout="List", pageSize=50 }, setOptions] = useState({});

    const { loading, products, usuals, pageCount } = useUsualsPage(pageNo, category, null, pageSize);

    const onPageChange = (pageNo) => {
        const params = {pageNo};
        if(category) params.category = category;
        setParams(params);
    }

    if(!loading && !products) {
        return <NoProducts />
    }

    return (
        <>
            <ProductLayout>
                <div className="product-layout-filters">
                    <OrderEntryNav usuals={usuals} category={category} />
                    <FilterContainer header="Options">
                        <ProductDisplayOptions
                            layout={layout}
                            pageSize={pageSize}
                            onChange={setOptions}
                        />
                    </FilterContainer>
                </div>
                {layout === "List" ?
                    <ProductList products={products} loading={loading} /> :
                    <ProductGrid
                        products={products}
                        loading={loading}>
                    </ProductGrid>
                }
            </ProductLayout>
            <Pagination
                loading={loading}
                lastPage={pageCount-1}
                currentPage={pageNo ? Number(pageNo) : 1}
                onPageRequested={onPageChange}
            />
        </>
    )

}

const OrderEntryPage = () => {


    return (
        <PageLayout requireSignIn>
            <div className="page-content">
                <BreadcrumbNavigation>
                    <Breadcrumb
                        name={"Home"}
                        nav={"/"}
                    />
                    <Breadcrumb
                        name={"Order Entry"}
                        nav={"/"}
                        selected
                    />
                </BreadcrumbNavigation>
                <OrderEntryContent />
            </div>
        </PageLayout>
    )

}

export default OrderEntryPage;
