import FilterGroup from "../../../../component/general/filter/FilterGroup";
import React from "react";
import FilterItemSelect from "../../../../component/general/filter/FilterItemSelect";

const ProductDisplayOptions = ({ layout, pageSize, onChange }) => {

    const onClick = (option, val) => () => {
        const updated = {
            layout, pageSize,
            [option]: val
        }
        onChange(updated);
    }

    return (
        <>
            <FilterGroup name="Product Layout">
                <FilterItemSelect name="Grid" selected={layout==="Grid"} onSelectChange={onClick("layout", "Grid")} />
                <FilterItemSelect name="List" selected={layout==="List"} onSelectChange={onClick("layout", "List")} />
            </FilterGroup>
            <FilterGroup name="Page Size">
                <FilterItemSelect name="20" selected={pageSize===20} onSelectChange={onClick("pageSize", 20)} />
                <FilterItemSelect name="50" selected={pageSize===50} onSelectChange={onClick("pageSize", 50)} />
                <FilterItemSelect name="100" selected={pageSize===100} onSelectChange={onClick("pageSize", 100)} />
            </FilterGroup>
        </>
    )
}

export default ProductDisplayOptions;
