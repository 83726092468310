import TertiaryNav from "../../../../component/navigation/TertiaryNav";

const OrderEntryNav = ({ usuals, category }) => {
    if (!usuals) return;

    return (
        <div className="product-tertiary-nav-container">
            <TertiaryNav
                nav="/test/page"
                name="All"
                selected={!category}
            />
            {usuals.categories.map((c,i) =>
                <TertiaryNav
                    key={i}
                    nav={"/test/page?category=" + c.id}
                    name={c.identifier}
                    selected={c.id + "" === category}
                />
            )}
        </div>
    )
}

export default OrderEntryNav;
