import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const creditStatusRequest = (bPartnerID) => {

    const req = request.model(api.MODEL.C_BPARTNER)
        .select("SO_CreditLimit", "creditLimitCalc", "totalOpenBalance")
        .filter(condition.eq("c_bpartner_ID", bPartnerID));

    const builder = {};

    builder.sendIt = async (session) => {
        const url = req.buildRequest(env.API_URL)
        const response = await HttpClient.client.get(url, api.auth(session));
        return {creditStatus: response.data.records[0]};
    }

    return builder;

}

export default creditStatusRequest;
