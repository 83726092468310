import createRequestRequest from "../helpDesk/createRequestRequest";

const newProductRequest = () => {

    const builder = {};

    builder.data = (data) => {
        builder.reqData = data;
        return builder;
    }

    const sendRequest = async (session, name, code) => {
        const req = createRequestRequest()
            .setSummary("New product request. Product name: " + name + ", Code: " + code)
            .setRequestTypeID(1000051)
            .setRoleID(1000102)
            .setConfidentialityType("I")
            .setPriority("3");

        await req.sendIt(session);
    }

    builder.sendIt = async (session) => {
        // const req = request.model("I_Product");
        // const url = req.buildRequest(env.API_URL);

        // const resp = await HttpClient.client.post(url, builder.reqData, api.auth(session));
        await sendRequest(session, builder.reqData.name, builder.reqData.VendorProductNo);
        return 1; //resp.data;
    }

    return builder;
}

export default newProductRequest;
