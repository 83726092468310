import useClearance from "../../../services/state/product/useClearance";
import { useMemo } from "react";
import PageProductID from "./PageProductID";
import {ProductPurchaseInfo} from "../../../../res/product/productPurchaseInfo";
import {useNavigate, useParams} from "react-router-dom";
import ProductDisplaySwitcher
    from "../../../sales/product/productDisplay/productDisplayswitcher/ProductDisplaySwitcher";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Clearance", nav: "/clearance", selected:true} ]

const PageClearance = () => {

    const navigate = useNavigate();
    const { type } = useParams();

    const {
        loading : clearanceLoading,
        clearanceIDs,
    } = useClearance({onlyRepacks: type==="repack", onlyOOD: type === "reduced"});

    const productIDs = useMemo(
        () => clearanceIDs?.map(id => id.M_Product_ID.id),
        [clearanceIDs]
    );

    const onPageChange = (pageNo, filter) => {
        navigate("/clearance/" + type + "/" + pageNo + filter);
    }

    const getNav = (pageNo, filterParam) => {
        return `/clearance/" + type + "/" ${pageNo}` + (filterParam ? "/" + filterParam : "");
    }

    const breadcrumbs = [...breadcrumbTrail];
    if(type !== "all") {
        breadcrumbs[1].selected = false;
        const name = type[0].toUpperCase() + type.slice(1);
        breadcrumbs.push({
            name, nav: '/clearance/' + type
        })
    }
    breadcrumbs[breadcrumbs.length - 1].selected = true;

    return (
        <PageProductID
            breadcrumbTrail={breadcrumbs}
            loading={clearanceLoading}
            productIDs={productIDs}
            onPageChange={onPageChange}
            getNav={getNav}
            getProductList={(products, loading, layout) => (
                <ProductDisplaySwitcher
                    products={products}
                    loading={loading}
                    display={layout}
                    getDisplayProduct={ProductPurchaseInfo.getClearanceDisplayProduct}
                />
            )}
        />
    )
}

export default PageClearance;
