import AccountLayout from "../structure/AccountLayout";
import AccountNav from "../../../../logic/navigation/accountNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import useBusinessLocations from "../../../../services/state/account/useBusinessLocations";
import AddressDisplay from "../../address/AddressDisplay";
import useIsAccountPage from "../structure/useIsAccountPage";
import VendorNav from "../../../../logic/navigation/vendorNav";
import {useMemo, useState} from "react";
import useDeliveryDates from "../../../../services/state/delivery/useDeliveryDates";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import Calendar from "../../../general/calendar/Calendar";

const acctBreadcrumbTrail = [AccountNav.items.home, AccountNav.items.settings, {...AccountNav.items.locations, selected: true}]
const vendBreadcrumbTrail = [VendorNav.items.home, VendorNav.items.settings, {...VendorNav.items.locations, selected: true}]

const DeliveryDateCalendar = ({ location }) => {

    const bPartnerID = location.C_BPartner_ID?.id;
    const bPartnerLocationID = location.id;

    const { loading, availableDates } = useDeliveryDates({bPartnerID, bPartnerLocationID});
    const justTheDates = useMemo(() => availableDates ? availableDates.map(d => new Date(d.available_date)) : [], [availableDates]);

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(minDate.getMonth() + 6);

    return (
        <LoadingSwitcher loading={loading}>
            <Calendar
                minDate={minDate}
                maxDate={maxDate}
                whiteList={justTheDates}
                onDateClick={() => {}}
            />
        </LoadingSwitcher>
    )

}

const DeliveryDateDetail = ({ location }) => {

    const [ showDeliveryDates, setShowDeliveryDates ] = useState(false);

    if(!showDeliveryDates) {
        return (
            <p className="clickable-p" onClick={() => setShowDeliveryDates(true)}>Show Delivery Dates</p>
        )
    }

    return (
        <>
            <DeliveryDateCalendar
                location={location}
            />
            <p className="clickable-p" onClick={() => setShowDeliveryDates(false)}>Hide</p>
        </>
    )

}

const LocationContent = () => {

    const {locations} = useBusinessLocations();
    const activeLocations = locations ? locations.filter(loc => loc.IsActive) : null;

    return (
        <div className="acct-content">
            {activeLocations && activeLocations.map((e,i) =>
                <AddressDisplay key={i} location={e} >
                    <DeliveryDateDetail location={e} />
                    {/*<Button className="button_clear" onClick={() => onEdit(e)}>Update</Button>*/}
                </AddressDisplay>
            )}
        </div>
    )
}

const LocationLayout = () => {

    const isAccount = useIsAccountPage();
    const breadcrumbTrail = isAccount ? acctBreadcrumbTrail : vendBreadcrumbTrail;

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={breadcrumbTrail[1].children} />
            <LocationContent />
        </AccountLayout>
    )
}

export default LocationLayout;
