import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const shipmentRequest = () => {

    const builder = {};

    const lineReq = request.subQueryList(api.MODEL.M_INOUT_LINE);
    const req = request.model(api.MODEL.M_INOUT)
        .orderBy("Created DESC")
        .hostURL(env.API_URL);

    builder.top = (top) => {
        req.top(top);
        return builder;
    }

    builder.isSoTrx = (isSoTrx) => {
        const docTypeID = isSoTrx ? 1000011 : 1000014
        req.filter(condition.eq("C_DocType_ID", docTypeID))
            .filter(condition.eq("isSoTrx", isSoTrx));
        return builder;
    }

    builder.skip = (skip) => {
        req.skip(skip);
        return builder;
    }

    builder.forIDs = (ids) => {
        req.filter(condition.in("m_inOut_ID", ...ids));
        return builder;
    }

    builder.forDocuments = (...documents) => {
        req.filter(condition.in("DocumentNo", ...documents))
    }

    builder.forDateRange = (start, end) => {
        req.filter(condition.greaterThan("movementDate", start));
        req.filter(condition.lessThan("movementDate", end));
        return builder;
    }

    builder.outForDelivery = () => {
        req.filter(condition.eq("docStatus", "'IP'"))
            .expand("c_bpartner_location_ID")
            .expand(request.subQuery("x_routeLines"));
        return builder;
    }

    builder.includeLines = () => {
        req.expand(lineReq);
        return builder;
    }

    builder.includeProducts = () => {
        lineReq.expand("M_Product_ID", "C_UOM_ID", "C_Charge_ID");
        return builder;
    }

    builder.sendIt = async (session) => {
        req.filter(condition.eq("c_bpartner_id", session.bPartnerID));
        const resp = await HttpClient.client.get(req.buildRequest(), api.auth(session));
        return {
            records: resp.data.records,
            page: resp.data["page-count"],
            count: resp.data["row-count"]
        };
    }

    return builder;
}

export default shipmentRequest;
