import AccountLayout from "../structure/AccountLayout";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import BannerForm from "../../../vendor/promotion/BannerForm";
import VendorNav from "../../../../logic/navigation/vendorNav";
import {useSearchParams} from "react-router-dom";
import {condition, request} from "../../../../../res/rest/restRequest";
import useAuthRequest from "../../../../services/state/request/useAuthRequest";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.promotions, {...VendorNav.items.bannerPromo, selected: true}]

const getPromoReq = (id) =>
    request.model("x_Upsell_Product")
        .filter(
            condition.eq("x_Upsell_Product_ID", id),
        )
        .get


const BannerPromotionContent = () => {

    const {
        loading,
        sendIt,
        result,
    } = useAuthRequest();


    const [params] = useSearchParams();
    const copy = params.get("copy");

    useValueChangeListener(() => sendIt(getPromoReq(copy)), [copy], []);

    return (
        <div className="acct-content">
            <LoadingSwitcher loading={loading}>
                <BannerForm
                    copy={result?.[0]}
                />
            </LoadingSwitcher>
        </div>
    )
}

const BannerPromotionLayout = () => {

    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.promotions.children} />
            <BannerPromotionContent />
        </AccountLayout>
    )
}

export default BannerPromotionLayout;
