
const DialogScrollView = ({ maxHeight=600, className, children }) => {

    return (
        <div className={className} style={{ overflowY: "scroll", maxHeight }}>
            {children}
        </div>
    )

}

export default DialogScrollView;
