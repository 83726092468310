
const CalendarIcon = () => {

    return (
        <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39" cy="39" r="39" fill="#F0F0F0"/>
            <circle cx="38.5" cy="38.5" r="24.5" fill="#6ECFE0" stroke="#51AABA" strokeWidth="2"/>
            <rect x="25" y="26" width="28" height="26" rx="4" fill="white" stroke="black" strokeWidth="2"/>
            <rect x="45" y="23" width="2" height="7" rx="1" fill="black"/>
            <rect x="31" y="23" width="2" height="7" rx="1" fill="black"/>
            <line x1="25" y1="33" x2="53" y2="33" stroke="black" strokeWidth="2"/>
            <line x1="28" y1="37" x2="34" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="44" y1="37" x2="50" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="36" y1="37" x2="42" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="28" y1="37" x2="34" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="44" y1="37" x2="50" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="36" y1="37" x2="42" y2="37" stroke="black" strokeWidth="2"/>
            <line x1="28" y1="42" x2="34" y2="42" stroke="black" strokeWidth="2"/>
            <line x1="44" y1="42" x2="50" y2="42" stroke="black" strokeWidth="2"/>
            <line x1="36" y1="42" x2="42" y2="42" stroke="black" strokeWidth="2"/>
            <line x1="28" y1="47" x2="34" y2="47" stroke="black" strokeWidth="2"/>
            <line x1="44" y1="47" x2="50" y2="47" stroke="black" strokeWidth="2"/>
            <line x1="36" y1="47" x2="42" y2="47" stroke="black" strokeWidth="2"/>
        </svg>
    )
}

export default CalendarIcon;
