import axios from "axios";
import {request} from "../../../res/rest/restRequest";
import {env} from "../../../res/config/env";
import ErrorReport from "../../logic/error/ErrorReport";

const sendErrorReport = async (e, url, body, options, type, remainingAttempts=1) => {
    if(2>1) return;

    if(url?.includes("tokens")) {
        console.log("Error not logged for security")
        return;
    }
    console.log("Security check pass")
    try {

        const errorDetails = await ErrorReport.buildErrorMessage(e);
        const error_message =  "URL: " + url + ",\n\n\nBody: " + JSON.stringify(body) + ",\n\n\nError: " + errorDetails;

        const errorData = {
            error_tag: "Axios error",
            web_page: type,
            error_message,
            URL: url,
        }

        const req = request.model("web_error").buildRequest(env.API_URL);
        await axios.post(req, errorData, options);

    } catch (e) {

        if(remainingAttempts > 0) {
            await sendErrorReport(e, url, body, options, type, remainingAttempts - 1);
        } else {
            console.log("Failed to send error report");
        }

    }

}

const get = async (url, options) => {
    try {
        return await axios.get(url, options);
    } catch (e) {
        await sendErrorReport(e, url, {}, options, "get");
        throw e;
    }
}
const put = async (url, body, options) => {
    try {
        return await axios.put(url, body, options);
    } catch (e) {
        await sendErrorReport(e, url, body, options, "put");
        throw e;
    }
}
const post = async (url, body, options) => {
    try {
        return await axios.post(url, body, options);
    } catch (e) {
        await sendErrorReport(e, url, body, options, "post");
        throw e;
    }
}

const deleteIt = async (url, options) => {
    try {
        return await axios.delete(url, options);
    } catch (e) {
        await sendErrorReport(e, url, {}, options, "delete");
        throw e;
    }
}

const AxiosClient = {
    get,
    put,
    post,
    delete: deleteIt,
}

export default AxiosClient;
