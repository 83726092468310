import {createAsyncThunk} from "@reduxjs/toolkit";
import {env} from "../../res/config/env";
import {api} from "../../res/rest/api";
import SolrField from "../../newStructure/logic/search/solrField";
import HttpClient from "../../newStructure/services/http/HttpClient";

const buildCategoryMap = (categoryList) => {
    const allCategories = {};
    const categoryMap = {};

    for(const category of categoryList) {
        allCategories[category.id] = category;
    }

    for(const category of categoryList) {
        if(category.isActive !== "Y") continue;

        if(category.parentID) {
            const parentID = category.parentID;
            const parent = allCategories[parentID]
            if(!parent) {
                console.warn("No parent found for category", category.id);
                console.log("No parent found for", category);
                continue;
            }

            if(!parent["children"]) {
                parent["children"] = [];
            }
            parent["children"].push(category)
        } else {
            categoryMap[category.id] = category;
        }
    }

    return categoryMap;
}

const formatCategory = (rawData) => {

    const category = {};
    category.id         = SolrField.getValue(rawData, "M_Product_Category_ID");
    category.parentID   = SolrField.getValue(rawData, "M_Product_Category_Parent_ID");
    category.name       = SolrField.getValue(rawData, "Name");
    category.isActive   = SolrField.getValue(rawData, "isActive");
    category.imageUrl   = SolrField.getValue(rawData, "imageUrl");

    return category;

}

const getSolrRequest = () => {
    const url = env.API_SOLR_URL;
    const select = "select?q="
    const paramList = "(Table_Name:M_Product_Category)AND(isActive:Y)AND(ad_org_ID:1000000)AND(ad_client_ID:1000000)";
    const pageSize = "&rows=" + 1000
    return url + select + paramList + pageSize;
}

const getSolrCategories = async () => {
    const req = getSolrRequest();
    const resp = await HttpClient.client.get(req);
    const records = resp.data.response.docs;

    return records.map(formatCategory);
}

export const getCategories = createAsyncThunk(
    'category/get',
    async ({session}, {rejectWithValue, dispatch}) => {
        try {
            const solrCategories = await getSolrCategories();
            const map = buildCategoryMap(solrCategories);
            return { map }
        } catch (error) {
            console.log("error", error)
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)


