import PageLayout from "./PageLayout";
import {Button} from "../general/input/Button";
import HttpClient from "../../newStructure/services/http/HttpClient";


const PageTest = () => {

    const runTest = async () => {
        HttpClient.client.post("api.suthbros.co.uk/notRealEndPoint", {aThing: "item"})
            .then(r => console.log(r))
            .catch(e => console.error(e))
    };


    return (
        <PageLayout>
            <div style={{minHeight: 600}}>
                <Button
                    style={{
                        height: "300px",
                        width: "70vw",
                        margin: "100px auto",
                        textAlign: "center",
                        display: "flex"
                    }}
                    onClick={runTest}>
                    <p style={{
                        margin: "auto auto",
                        fontSize: "10em"
                    }}>Test</p>
                </Button>
            </div>
        </PageLayout>
    )

}

export default PageTest;
