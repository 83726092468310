import {condition, request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import HttpClient from "../../../http/HttpClient";

const outForDeliveryRequest = () => {

    const builder = {};

    const req = request.model("web_out_for_delivery")
        .select("DocumentNo", "Last_Seen", "X_TimeArrived", "x_actualtimedep", "bp_loc_lat", "bp_loc_long",
            "driver_lat", "driver_long", "stops")
        .hostURL(env.API_URL);

    builder.bPartnerID = (bpartnerID) => {
        req.filter(condition.eq("C_BPartner_ID", bpartnerID));
        return builder;
    }

    builder.sentIt = async (session) => {
        const resp = await HttpClient.client.get(req.buildRequest(), api.auth(session));
        return resp.data?.records[0];
    }

    return builder;
}

export default outForDeliveryRequest;
