import CartDeliveryLocation from "./CartDeliveryLocation";
import CartDeliveryMethod from "./CartDeliveryMethod";
import CartDeliveryDate from "./CartDeliveryDate";
import {useState} from "react";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";

const MULTI_SELECT_BLOCKER = "multiSelect";

const CartDelivery = ({ checkout }) => {

    const [ enforceSelection, setEnforceSelection ] = useState(false);
    const [selecting, setSelecting] = useState(false);

    const afterSelect = () => {
        setSelecting(false);
        if(enforceSelection) {
            setEnforceSelection(false);
            checkout.deleteBlocker(MULTI_SELECT_BLOCKER)
        }
    }

    useValueChangeListener(afterSelect, [checkout.getBPartnerLocationID()]);

    const locs = checkout.getLocations();
    useValueChangeListener(() => {
        if(locs && locs.length > 1) {
            setEnforceSelection(true);
            checkout.addBlocker(MULTI_SELECT_BLOCKER, "Please select a location.");
        }
    }, [locs], []);

    return (
        <>
            <CartDeliveryLocation
                checkout={checkout}
                selecting={selecting}
                enforceSelection={enforceSelection}
                setSelecting={setSelecting}
                afterSelect={afterSelect}
            />
            {!selecting && !enforceSelection &&
                <>
                    <CartDeliveryMethod  checkout={checkout} />
                    <CartDeliveryDate checkout={checkout} />
                </>
            }
        </>
    )
}

export default CartDelivery;
