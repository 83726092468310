import PartnerDeliverySwitcher from "./PartnerDeliverySwitcher";
import './partnerHome.css'
import ContentContainer from "../content/ContentContainer";
import ContentTile from "../content/ContentTile";
import DocumentIcon from "../../general/icon/DocumentIcon";
import CalendarIcon from "../../general/icon/CalendarIcon";

const PartnerHome = () => {

    return (
        <div className="partner-home-layout">
            <PartnerDeliverySwitcher />
            <ContentContainer>
                <p className="home-content-header">Your Account</p>
                <ContentTile
                    title={"Active Orders"}
                    text={"Amend an order before its shipped"}
                    navText={"Amend order"}
                    link={"account/orders/openOrder/"}
                    icon={<DocumentIcon />}
                />
                <ContentTile
                    title={"Delivery Dates"}
                    text={"View your available delivery dates"}
                    navText={"View delivery dates"}
                    link={"account/settings/locations"}
                    icon={<CalendarIcon />}
                />
            </ContentContainer>
        </div>
    )

}

export default PartnerHome;
