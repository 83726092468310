
const users = {name: "Contacts", nav: "/vendor/accountInformation/contacts"};
const locations = {name: "Locations", nav: "/vendor/accountInformation/locations"};
const settings = {name: "Account Information", nav: "/vendor/accountInformation", children: [users, locations]};

const unpaidAndUpcoming = {name: "Payments Due", nav: "/vendor/payments/paymentsDue", };
const payment = {name: "Payment Remits", nav: "/vendor/payments/paymentRemits"};
const payments = {name: "Payments", nav: "/vendor/payments", children: [payment, unpaidAndUpcoming]};

const order = {name: "Purchase Orders", nav: "/vendor/orders/purchaseOrders"};
const shipment = {name: "Delivery Note", nav: "/vendor/orders/deliveryNote"};
const invoice = {name: "Invoices", nav: "/vendor/orders/invoices"}; // TODO should this be in payments????
const orders = {name: "Orders", nav: "/vendor/orders",  children: [order, shipment, invoice]};

const getHelp = {name: "Get Help", nav: "/vendor/helpDesk/getHelp"};
const myTickets = {name: "My Tickets", nav: "/vendor/helpDesk/myTickets"};

const viewProducts = {name: "Review your Products", nav: "/vendor/manageStock/reviewYourProducts"};
// const listProduct  = {name: "List New Product", nav: "/vendor/manageStock/listNewProduct"};
const manageStock = {name: "Manage Stock", nav: "/vendor/manageStock", children: [viewProducts]};

const bannerPromo   = {name: "Create Banner Promotion", nav: "/vendor/promotion/createBannerPromotion"}
const previousPromo = {name: "Past Promotions", nav: "/vendor/promotion/pastPromotions"}
const promotions = {name: "Promotion", nav: "/vendor/promotion", children: [bannerPromo, previousPromo]}

const home = {name: "Home", nav: "/"};


const items = {
    users,
    locations,
    settings,

    unpaidAndUpcoming,
    payment,
    payments,

    order,
    shipment,
    invoice,
    orders,

    getHelp,
    myTickets,

    viewProducts,
    // listProduct,
    manageStock,

    bannerPromo,
    previousPromo,
    promotions,

    home,
}

const list = [
    settings,
    payments,
    orders,
    manageStock,
    promotions,
]

const VendorNav = {
    list,
    items,
}

export default VendorNav;
