import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const erpProductRequest = () => {

    const builder = {};

    const req = request.model("m_product").hostURL(env.API_URL);

    builder.ids = (...productIDs) => {
        req.filter(condition.in("m_product_ID", ...productIDs));
        return builder;
    }

    builder.includeBarcodes = () => {
        req.expand(request
            .subQueryList("X_Product_Barcode")
            .filter(condition.eq("isActive", "true"))
        );
        return builder;
    }

    builder.sendIt = async (session) => {
        const response = await HttpClient.client.get(req.buildRequest(), api.auth(session));
        return response.data.records;
    }

    return builder;

}

export default erpProductRequest;
