import {condition, request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import MapUtil from "../../../../logic/collection/mapUtil";
import HttpClient from "../../../http/HttpClient";

// const getBaseInfo = async (session, params) => {
//
//     const req = request.model("web_product_purchase_info_v")
//         .filter(condition.eq("c_bpartner_ID", params.bPartnerID))
//         .hostURL(env.API_URL);
//
//     req.filter(condition.in("m_product_ID", ...params.productIDs));
//     const result = await HttpClient.client.get(req.buildRequest(), api.auth(session));
//     return result.data.records;
// }

const getStock = async (session, params) => {
    const req = request.model("web_product_stock_v")
        .filter(condition.eq("AD_Org_ID", params.orgID))
        .filter(condition.in("m_product_ID", ...params.productIDs))
        .hostURL(env.API_URL)

    const result = await HttpClient.client.get(req.buildRequest(), api.auth(session));
    return result.data.records;
}

const getPrices = async (session, params) => {
    const req = request.model("web_product_price_v")
        .filter(condition.eq("c_bpartner_ID", params.bPartnerID))
        .filter(condition.in("m_product_ID", ...params.productIDs))
        .hostURL(env.API_URL)

    const result = await HttpClient.client.get(req.buildRequest(), api.auth(session));
    return result.data.records;
}

const getBreaks = async (session, params) => {
    const req = request.model("price_bpartner_break_current_v")
        .filter(condition.eq("c_bpartner_ID", params.bPartnerID))
        .filter(condition.in("m_product_ID", ...params.productIDs))
        .hostURL(env.API_URL)

    const result = await HttpClient.client.get(req.buildRequest(), api.auth(session));
    return result.data.records;
}

const getDiscounts = async (session, params) => {
    const req = request.model("web_product_discount_v")
        .filter(condition.eq("isActive", "true"))
        .filter(condition.eq("AD_Org_ID", params.orgID))
        .filter(condition.in("m_product_ID", ...params.productIDs))
        .hostURL(env.API_URL);


    const result = await HttpClient.client.get(req.buildRequest(), api.auth(session));
    return result.data.records;
}

const resultBuilder = () => {

    const builder = {};
    const productMap = {};

    const addToProductMap = (id, toAdd) => {
        const info = productMap[id];
        productMap[id] = {
            ...info,
            ...toAdd
        }
    }

    builder.addPrices = (prices) => {
        prices.forEach(p => addToProductMap(p.id, p))
        return builder;
    }

    builder.addStock = (stock) => {
        stock.forEach(sl => addToProductMap(sl.id, sl));
        return builder;
    }

    builder.addDiscounts = (discounts) => {
        const discountsByPid = {};
        discounts.forEach(discount => {
            const pid = discount.web_product_v_ID;
            if(!discountsByPid[pid]) discountsByPid[pid] = [];
            discountsByPid[pid].push(discount);
        });

        MapUtil.forEach(discountsByPid, (id, lines) => addToProductMap(id, { web_product_discount_v : lines }));
        return builder;
    }

    builder.addBreaks = (breaks) => {
        const breaksByPid = {};
        breaks.forEach(priceBreak => {
            const pid = priceBreak.web_product_v_ID;
            if(!breaksByPid[pid]) breaksByPid[pid] = [];
            breaksByPid[pid].push(priceBreak);
        });

        MapUtil.forEach(breaksByPid, (id, lines) => addToProductMap(id, { price_bpartner_break_current_v : lines }));
        return builder;
    }

    builder.getProductInfo = () => {
        return Object.values(productMap);
    }

    return builder;
}

const productPurchaseInfoRequest = (orgID, bPartnerID) => {

    const requestBuilder = {};
    const params = {
        orgID,
        bPartnerID
    };

    requestBuilder.productIDs = (productIDs) => {
        params.productIDs = productIDs;
        return requestBuilder;
    }

    requestBuilder.sendRequest = async (session) => {
        const promiseOfPrices = getPrices(session, params);
        const promiseOfStock = getStock(session, params);
        const promiseOfDiscounts = getDiscounts(session, params);
        const promiseOfBreaks = getBreaks(session, params);

        return resultBuilder()
            .addPrices(await promiseOfPrices)
            .addStock(await promiseOfStock)
            .addDiscounts(await promiseOfDiscounts)
            .addBreaks(await promiseOfBreaks)
            .getProductInfo();
    }

    return requestBuilder;

}

export default productPurchaseInfoRequest;
