import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const backOrderRequest = () => {

    const req = request.model(api.MODEL.BACK_ORDER)
        .select("c_bpartner_location_ID", "c_order_ID", "c_orderLine_ID",
            "created", "minpoarrival", "MovementQty", "M_Product_ID", "orderedStockStatus",
            "c_uom_ID")
        .expand(request.subQueryList("M_Product_ID").select("Value", "Name", "imageurl"))
        .expand(request.subQuery("C_UOM_ID"))
        .expand(request.subQueryList("C_Order_ID").select("DocumentNo"));


    const builder = {};

    builder.sendIt = async (session) => {
        req.filter(condition.eq("C_BPartner_ID", session.bPartnerID));
        const url = req.buildRequest(env.API_URL);
        const response = await HttpClient.client.get(url, api.auth(session));
        return response.data.records;
    }

    return builder;
}

export default backOrderRequest;
