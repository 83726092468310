import usePromotions from "../../../services/state/product/usePromotions";
import { useMemo } from "react";
import ProductPromotionList from "../../product/ProductPromotionList";
import PageProductID from "./PageProductID";
import {useNavigate} from "react-router-dom";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Promotion", nav: "/promotion", selected:true} ]

const PagePromotion = () => {

    const navigate = useNavigate();

    const {
        loading : promotionLoading,
        promotions,
    } = usePromotions();

    const productIDs = useMemo(
        () => promotions?.map(promo => promo.id),
        [promotions]
    );

    const onPageChange = (pageNo, filter) => {
        navigate("/promotion/" + pageNo + filter);
    }

    const getNav = (pageNo, filterParam) => {
        return `/promotion/${pageNo}` + (filterParam ? "/" + filterParam : "");
    }

    return (
        <PageProductID
            breadcrumbTrail={breadcrumbTrail}
            loading={promotionLoading}
            productIDs={productIDs}
            onPageChange={onPageChange}
            getNav={getNav}
            getProductList={(products, loading, layout) => (
                <ProductPromotionList
                    promotions={promotions}
                    products={products}
                    loading={loading}
                    layout={layout}
                />
            )}
        />
    )
}

export default PagePromotion;
