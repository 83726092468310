import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import {request} from "../../../../../res/rest/restRequest";
import MapUtil from "../../../../logic/collection/mapUtil";
import JSZip from "jszip";
import HttpClient from "../../../http/HttpClient";

const bannerFormRequest = () => {

    const builder = {};
    const data = {};
    const attachments = [];

    builder.setProduct = (product) => {
        if(product) {
            const url = "/product/" + product.id;
            data.M_Product_ID = product;
            data.navigationURL = url;
        }
        return builder;
    }

    builder.setStartDate = (date) => {
        data.StartDate = date;
        return builder;
    }

    builder.setEndDate = (date) => {
        data.EndDate = date;
        return builder;
    }

    builder.setProductCategoryID = (categoryID) => {
        data.M_Product_Category_ID = categoryID;
        return builder;
    }

    builder.setDescription = (description) => {
        data.Description = description;
        return builder;
    }

    builder.setBPartnerID = (bpID) => {
        data.C_BPartner_ID = bpID;
        return builder;
    }

    builder.setProductList = (productList) => {
        if(!productList) return builder;

        const productValueList = MapUtil.mapToList(productList, (k,v) => v.value);
        if(productValueList.length > 0) {
            data.productValue = productValueList.join(",");
            data.navigationURL = "/search/" + productValueList.join(" ");
        }

        return builder;
    }

    builder.setBannerClickAction = (clickAction) => {
        return builder;
    }

    builder.setUpsellType = (type) => {
        data.x_upsell_type_ID = type;
        return builder;
    }

    builder.addAttachment = (attachment) => {
        attachments.push(attachment);
        return builder;
    }

    const createBannerForm = async (session) => {
        const req = request.model("x_Upsell_Product");
        const url = req.buildRequest(env.API_URL);

        const resp = await HttpClient.client.post(url, data, api.auth(session));
        return resp.data;
    }

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result.split(",")[1]; // Remove the "data:application/zip;base64," prefix
                resolve(base64data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const attachFiles = async (session, id, files) => {
        const zip = new JSZip();

        for(const f of files) {
            zip.file(f.name, f.data.replace(/^data:image\/[a-z]+;base64,/, ""), {base64: true});
        }

        const req = request.model("x_Upsell_Product")
            .id(id)
            .attachment(true);

        const blob = await zip.generateAsync({ type: "blob" });
        const data = await blobToBase64(blob);

        const url = req.buildRequest(env.API_URL) + "/zip";
        return await HttpClient.client.post(url, {data}, api.auth(session));
    }

    builder.sendIt = async (session) => {
        builder.setBPartnerID(session.bPartnerID);

        const res = await createBannerForm(session);
        const id = res.id;
        await attachFiles(session, id, attachments);

        return "done";
    }

    return builder;
}

export default bannerFormRequest;
