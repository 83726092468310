import AccountLayout from "../structure/AccountLayout";
import VendorNav from "../../../../logic/navigation/vendorNav";
import AccountTertiaryNav from "../structure/AccountTertiaryNav";
import {condition, request} from "../../../../../res/rest/restRequest";
import LoadingSwitcher from "../../../general/loading/LoadingSwitcher";
import useAuthRequest from "../../../../services/state/request/useAuthRequest";
import useValueChangeListener from "../../../../services/state/general/useValueChangeListener";
import LinkText from "../../../navigation/link/LinkText";
import ImageLazyLoad from "../../../../../components/general/loading/ImageLazyLoad";
import {useState} from "react";
import ReRunPromotionPopUp from "../../../vendor/promotion/ReRunPromotionPopUp";

const breadcrumbTrail = [VendorNav.items.home, VendorNav.items.promotions, {...VendorNav.items.previousPromo, selected: true}]

const getPromoReq = (bpID) =>
    request.model("x_Upsell_Product")
        .filter(
            condition.eq("c_bpartner_ID", bpID),
            condition.eq("x_upsell_type_ID", "'B'")
        )
        .orderBy("created DESC")
        .get

const formatDate = (date) => {
    if(!date) return "--";

    const jsDate = new Date(date);
    const day = String(jsDate.getDate()).padStart(2, '0');
    const month = String(jsDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = jsDate.getFullYear();
    return `${day}/${month}/${year}`
}

const Promotion = ({ promotion }) => {

    const start = formatDate(promotion.StartDate);
    const end = formatDate(promotion.EndDate);
    const catName = promotion.M_Product_Category_ID?.identifier;

    const [ displayRerun, setDisplayRerun ] = useState();

    return (
        <div className="acct-line">
            {displayRerun &&
                <ReRunPromotionPopUp
                    promotion={promotion}
                    onBack={() => setDisplayRerun(false)}
                />
            }
            <p className="acct-line-h1">{catName}</p>
            <p style={{marginTop: 2}} className="acct-line-h1">{start} - {end}</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <LinkText href={promotion.NavigationURL}>Linked Page</LinkText>
                <p className="link-text" onClick={() => setDisplayRerun(true)}>Re-Run Promotion</p>
            </div>
            <div style={{display: "flex", gap: 20, marginTop: 8}}>
                <div>
                    <p className="acct-line-h2">Skyscraper</p>
                    <ImageLazyLoad
                        style={{maxHeight: "200px"}}
                        src={promotion.skyscraperImageUrl}
                    />
                </div>
                <div>
                    <p className="acct-line-h2">Leader</p>
                    <ImageLazyLoad
                        style={{maxWidth: "480px"}}
                        src={promotion.leaderboardImageUrl}
                    />
                    <p className="acct-line-h2">Mobile leader</p>
                    <ImageLazyLoad
                        src={promotion.mobileLeaderBoardImageUrl}
                    />
                </div>
            </div>
        </div>
    )
}

const PreviousPromotionContent = () => {

    const {
        loading,
        sendIt,
        session,
        result
    } = useAuthRequest();

    const bpID = session?.sessionData?.bPartnerID;

    useValueChangeListener(() => {
        if (bpID > 0) sendIt(getPromoReq(bpID))
    }, [bpID], []);

    return (
        <div className="acct-content">
            <LoadingSwitcher loading={loading}>
                {(!result || result.length === 0) &&
                    <p className="acct-h2">No Promotions have been found.</p>
                }
                {(result && result.length > 0) && result.map((p,i) =>
                    <Promotion
                        key={i}
                        promotion={p}
                    />
                )}
            </LoadingSwitcher>
        </div>
    )

}

const PreviousPromotionsLayout = () => {


    return (
        <AccountLayout breadcrumbTrail={breadcrumbTrail}>
            <AccountTertiaryNav navList={VendorNav.items.promotions.children} />
            <PreviousPromotionContent />
        </AccountLayout>
    )

}

export default PreviousPromotionsLayout;
