import {request} from "../../../../../res/rest/restRequest";
import {env} from "../../../../../res/config/env";
import {api} from "../../../../../res/rest/api";
import HttpClient from "../../../http/HttpClient";

const errorReportRequest = (session) => {

    const builder = {};

    const errorData = {}

    builder.tag = (tag) => {
        errorData.error_tag = tag;
        return builder;
    }

    builder.page = (page) => {
        errorData.web_page = page;
        return builder;
    }

    builder.message = (message) => {
        errorData.error_message = message;
        return builder;
    }

    builder.url = (url) => {
        errorData.URL = url;
        return builder;
    }

    builder.sendIt = async (remainingAttempts = 0) => {
        const req = request.model("web_error").buildRequest(env.API_URL);
        try {
            return await HttpClient.client.post(req, errorData, api.auth(session));
        } catch (e) {
            if(remainingAttempts > 0) {
                return builder.sendIt(remainingAttempts - 1);
            } else {
                return e;
            }
        }
    }


    return builder;
}

export default errorReportRequest;
