import PageVendor from "../../vendor/PageVendor";
import PreviousPromotionsLayout from "../../../account/layout/promotion/PreviousPromotionsLayout";

const PagePreviousPromotions = () => {

    return (
        <PageVendor>
            <PreviousPromotionsLayout />
        </PageVendor>
    )

}

export default PagePreviousPromotions;
