import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import {idempiereProcess} from "../../../../../res/rest/process";
import HttpClient from "../../../http/HttpClient";

const resetPasswordRequest = () => {

    const req = request.process(api.PROCESS.RESET_PASSWORD)

    const builder = {};

    builder.token = (token) => {
        req.param("token", token);
        return builder;
    }

    builder.password = (password) => {
        const base64Pass = btoa(password);
        req.param("password", base64Pass);
        return builder;
    }

    builder.sendIt = async () => {
        const url = env.API_URL + req.buildRequest();
        const resp = await HttpClient.client.post(url, req.buildRequestBody(), api.auth());
        const data = idempiereProcess.handleResponseThrowingException(resp);
        return data.summary;
    }

    return builder;

}

export default resetPasswordRequest;
