import {useState} from "react";
import {useSelector} from "react-redux";
import {condition, request} from "../../../res/rest/restRequest";
import {env} from "../../../res/config/env";
import {api} from "../../../res/rest/api";
import {idempiereDate} from "../../../res/dataServices/idempiereDate";
import HttpClient from "../../../newStructure/services/http/HttpClient";
import useValueChangeListener from "../../../newStructure/services/state/general/useValueChangeListener";

const getCutOffRequest = () => {
    return request.model("web_shipment_cutoff_v")
        .filter(condition.eq("ad_client_ID", 1000000),
            condition.eq("isActive", "true"))
        .buildRequest(env.API_URL);
}

const getServerCutOff = async (session) => {
    try {
        const req = getCutOffRequest(session);
        const resp = await HttpClient.client.get(req, api.auth(session));
        const records = resp?.data?.records;
        if(records && records.length > 0) {
            return records[0]
        }
    } catch (error) {
    }
    return null;
}

const getLocalCutOff = (cutOffData) => {
    if(!cutOffData) return null;

    const todaysCutOff = idempiereDate.getDate(cutOffData.todayscutoff);
    const serverRemaining = cutOffData.timeremaining;
    const now = new Date();

    const localRemaining = todaysCutOff.getTime() - now.getTime();
    const remainingComparison = Math.abs(serverRemaining - localRemaining);
    const oneMinute = 1000 * 60;

    if(remainingComparison > oneMinute) {
        now.setTime(now.getTime() + serverRemaining);
        return now;
    } else {
        return todaysCutOff;
    }
}

const useDeliveryCutoff = () => {

    const { session } = useSelector(state => state.local.auth);
    const [ cutOff, setCutOff ] = useState(null);

    useValueChangeListener(async () => {
        if(!session) return;
        const cutOffData = await getServerCutOff(session)
        const calculatedCutOff = getLocalCutOff(cutOffData);
        setCutOff(calculatedCutOff);
    }, [session], []);

    return cutOff;
}

export default useDeliveryCutoff;
