import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import useCategoryPage from "../../services/state/product/useCategoryPage";
import useWebUpsell from "../../services/state/promotion/useWebUpsell";
import {useMediaQuery} from "@mui/material";
import {Advert} from "../../logic/promotion/advert";
import useSolrFilters from "../../services/state/product/useSolrFilters";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import breadcrumb from "../../logic/navigation/breadcrumb";
import usePageBreadcrumbTracker from "../../services/state/navigation/usePageBreadcrumbTracker";
import FilterUrl from "../../logic/filter/filterUrl";
import OrderBy from "../../logic/filter/orderBy";
import Leaderboard from "../promotion/Leaderboard";
import {Breadcrumb, BreadcrumbNavigation} from "../navigation/Breadcrumb";
import ProductLayout from "./ProductLayout";
import ReactiveNavContainer from "../navigation/ReactiveNavDrawer";
import ProductFilter from "./ProductFilter";
import Skyscraper from "../promotion/Skyscraper";
import Pagination from "../navigation/Pagination";
import TertiaryNav from "../navigation/TertiaryNav";
import ProductDisplaySwitcher from "../../sales/product/productDisplay/productDisplayswitcher/ProductDisplaySwitcher";
import ProductDisplayLayoutOptions
    from "../../sales/product/productDisplay/productDisplayOptions/ProductDisplayLayoutOption";
import ProductDisplayPageSizeOption
    from "../../sales/product/productDisplay/productDisplayOptions/ProductDisplayPageSizeOption";

const ProductCategory = ({ showFilterDrawer, setShowFilterDrawer }) => {

    const navigate = useNavigate();

    const { categoryID : categoryIDParam, pageNo : pageNoParam, filter : filterParam } = useParams();
    const { categoryMap } = useSelector(
        state =>  state.session.category
    )

    const [ layout, setLayout ] = useState("Grid");
    const [ pageSize, setPageSize ] = useState(20);

    const pageNo = pageNoParam && !isNaN(Number(pageNoParam)) ? Number(pageNoParam) : 1;
    const categoryID = Number(categoryIDParam);

    const {
        loading,
        products,
        pageCount
    } = useCategoryPage(pageNo, categoryID, filterParam, pageSize);

    const {
        promotions
    } = useWebUpsell({categoryID});

    const smallScreen = useMediaQuery('(max-width:768px)');
    const { leaderboard, skyscraper, mobileLeaderboard } = Advert.getAdsToShow(promotions, smallScreen);

    const {
        filters,
        getFilters
    } = useSolrFilters();

    useValueChangeListener(() => getFilters({categoryID}), [categoryID], [])
    useValueChangeListener(() => window.scrollTo(0, 0), [pageNo])

    const breadcrumbTrail = useMemo(
        () => breadcrumb.getBreadcrumbTrail(categoryMap, categoryID, categoryID, [{name: "Home", selected: false, nav: "/"}]),
        [categoryMap, categoryID]
    )

    usePageBreadcrumbTracker(breadcrumbTrail, [categoryID, pageNo, filterParam],
        () => `/category/${categoryID}/${pageNo}${(filterParam ? "/" + filterParam : "")}`);

    const onPageChange = (pageNo, filter) => {
        const url = "/category/" + categoryID + "/" + pageNo + (filter ? "/" + filter : (filterParam ? "/" + filterParam : ""));
        navigate(url);
    }

    const onApply = (selectedFilters) => {
        const filter = FilterUrl.getUrl(selectedFilters);
        setShowFilterDrawer(false);
        onPageChange(1, filter);
    }

    const subCategories = useMemo(() => {
        return breadcrumb.getTertiaryNav(categoryMap, categoryID);
    }, [categoryMap, categoryID]);

    const onPageSizeChange = (newSize) => {
        setPageSize(newSize);
        onPageChange(1);
    }

    const filtersWithOrdering = filters ? {...filters, [OrderBy.ATTRIBUTE_SET.id]: OrderBy.FILTER} : null;

    return (
        <div className="page-content product-content">
            {leaderboard &&
                <Leaderboard imageUrl={leaderboard.leaderboard} link={leaderboard.link}/>
            }
            {mobileLeaderboard &&
                <Leaderboard imageUrl={mobileLeaderboard.mobleader} link={mobileLeaderboard.link}/>
            }
            <BreadcrumbNavigation>
                {breadcrumbTrail.map((crumb, i) =>
                    <Breadcrumb
                        key={i}
                        name={crumb.name}
                        nav={crumb.nav}
                        selected={crumb.selected}
                    />
                )}
            </BreadcrumbNavigation>
            <ProductLayout>
                <div className="product-layout-filters">
                    <div className="product-tertiary-nav-container">
                        {subCategories && subCategories.map((cat, i) =>
                            <TertiaryNav
                                key={i}
                                nav={"/category/" + cat.id}
                                name={cat.name}
                                selected={cat.id === categoryID}
                            />
                        )}
                    </div>
                    <ReactiveNavContainer
                        display={showFilterDrawer}
                        onHide={() => setShowFilterDrawer(false)}
                    >
                        <ProductFilter
                            filters={filtersWithOrdering}
                            onApply={onApply}
                            defaultParam={filterParam}
                            dependants={[categoryID]}
                        >
                            <ProductDisplayLayoutOptions
                                layout={layout}
                                onChange={setLayout}
                            />
                            <ProductDisplayPageSizeOption
                                pageSize={pageSize}
                                onChange={onPageSizeChange}
                            />
                        </ProductFilter>
                    </ReactiveNavContainer>
                    {skyscraper &&
                        <Skyscraper imageUrl={skyscraper.skyscraper} link={skyscraper.link}/>
                    }
                </div>
                <ProductDisplaySwitcher
                    display={layout}
                    products={products}
                    loading={loading}>
                </ProductDisplaySwitcher>
            </ProductLayout>
            <Pagination
                currentPage={pageNo}
                lastPage={pageCount - 1}
                onPageRequested={onPageChange}
                loading={loading}
            />
        </div>
    )

}

export default ProductCategory;
