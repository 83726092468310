import {useDispatch, useSelector} from "react-redux";
import IFrameDeviceDataCollection from "./IFrameDeviceDataCollection";
import {step1, step3, step5} from "../../redux/action/paymentActions";
import {onStep2Result, onStep4Result, setPaymentDetails} from "../../redux/slice/paymentSlice";
import IFrameStepUp from "./IFrameStepUp";
import DialogLoading from "../../newStructure/component/general/dialog/DialogLoading";

// Step 1 (To be called outwith function to start process)
export const runInitialPaymentStep = (dispatch, session, paymentDetails) => {

    const clientRef = new Date().getTime()+"";

    const details = {
        ...paymentDetails,
        clientref: clientRef
    }

    dispatch(setPaymentDetails({data: details}));
    dispatch(step1({session, details}))
}

const doStep2 = (loading, error, paymentProcess) => {
    return !loading
        && !error
        && paymentProcess
        && paymentProcess.step1
        && !paymentProcess.step1.success
        && !paymentProcess.step2;
}

const doStep3 = (loading, error, paymentProcess) => {
    return ! loading
        && ! error
        && paymentProcess
        && paymentProcess.step2
        && ! paymentProcess.step3;
}

const doStep4 = (loading, error, paymentProcess) => {
    return ! loading
        && ! error
        && paymentProcess
        && paymentProcess.step3
        && paymentProcess.step3.stepUpUrl
        && !paymentProcess.step3.success
        // && paymentProcess.step3.status === "PENDING_AUTHENTICATION"
        && paymentProcess.step3.accessToken
        && ! paymentProcess.step4;
}

const doStep5 = (loading, error, paymentProcess) => {
    return ! loading
        && ! error
        && paymentProcess
        && ( paymentProcess.step4
            || (paymentProcess.step3 &&
                    !(paymentProcess.step3.status === "AUTHENTICATION_FAILED")
                    &&
                (!paymentProcess.step3.accessToken
                    || paymentProcess.step3.status === "AUTHENTICATION_SUCCESSFUL")
            ))
        && ! paymentProcess.step5;
}

const showLoading = (loading, error, paymentProcess) => {
    return loading
        || doStep2(loading, error, paymentProcess)
        || doStep3(loading, error, paymentProcess)
        || doStep5(loading, error, paymentProcess);
}

const PaymentProcessor = ({ session }) => {

    const dispatch = useDispatch();

    const { loading, error, paymentProcess, paymentDetails } = useSelector(
        state => state.session.payment
    )

    const onDataCollectionResult = (data) => {
        console.log("step 2 response", data)
        dispatch(onStep2Result({data}));
    }

    const onStepUpResult = (data) => {
        dispatch(onStep4Result({data}));
    }

    if(doStep3(loading, error, paymentProcess)) {
        const step3Data = {
            ...paymentDetails,
            clientref: paymentProcess.step1.clientref,
            ref: paymentProcess.step1.referenceId
        }
        dispatch(step3({session, details: step3Data}));
    }

    if(doStep5(loading, error, paymentProcess)) {
        const transactionID = paymentProcess.step3.status === "AUTHENTICATION_SUCCESSFUL" ?
            paymentProcess.step3.transactionID : paymentProcess.step4.transactionID;
        const CAVV = paymentProcess?.step3?.CAVV;
        const ECI = paymentProcess?.step3?.ECI;


        const details = {
            transactionID,
            CAVV, ECI,
            ...paymentDetails
        }

        if(!paymentProcess.step4) {
            details.id = paymentProcess.step3.id;
            details.step3Status = paymentProcess.step3.status;
        }

        dispatch(step5({session, details}))
    }

    const tryGetStepUpSize = () => {
        try {

            const pareq = paymentProcess.step3.pareq;
            const info = JSON.parse(atob(pareq));
            return info.challengeWindowSize;

        } catch (error) {}
    }

    const getPaymentMessage = () => {
        const message = "Please do not redirect from this screen. ";

        if(paymentProcess) {

            if(!paymentProcess.step1) {
                return message + "Connecting to payment processor."
            } else if(!paymentProcess.step2) {
                return message + "Collecting confirmation data."
            } else if(!paymentProcess.step3) {
                return message + "Requesting payment."
            } else {
                return message + "Confirming payment.";
            }
        }
        return message;
    }

    return (
        <>
            <DialogLoading title="Processing Payment" message={getPaymentMessage()} loading={showLoading(loading, error, paymentProcess)} />
            {doStep2(loading, error, paymentProcess) ?
                <IFrameDeviceDataCollection
                    accessToken={paymentProcess.step1.accessToken}
                    deviceDataCollectionUrl={paymentProcess.step1.stepUpUrl}
                    onDataCollectionResult={onDataCollectionResult}
                /> :
                <></>
            }
            {doStep4(loading, error, paymentProcess) ?
                <IFrameStepUp
                    url={paymentProcess.step3.stepUpUrl}
                    dimensionCode={tryGetStepUpSize()}
                    accessToken={paymentProcess.step3.accessToken}
                    onResponseReceived={onStepUpResult}
                /> :
                <></>
            }
        </>
    )

}

export default PaymentProcessor;
