import React, { useEffect } from 'react';
import useWebProduct from "../../../../../components/state/useWebProduct";
import {ProductPurchaseInfo} from "../../../../../res/product/productPurchaseInfo";
import ProductTile from "../../../../component/product/ProductTile";
import '../../../../component/style/product/productLayout.css'
import {range} from "../../../../../res/dataServices/range";
import ProductTileSkeleton from "../../../../component/product/ProductTileSkeleton";
import ReactGA from "react-ga4";

const ProductDisplay = ({ product, getDisplayProduct}) => {

    const { webProduct } = useWebProduct({product});
    const displayProduct = getDisplayProduct ? getDisplayProduct(webProduct) :
        ProductPurchaseInfo.getGenericDisplayProduct(webProduct);

    return (
        <ProductTile product={displayProduct} />
    )
}

const trackListing = (loading, products) => {
    if (!loading && products && products.length > 0) {

        const items = products.map(product => ({
            item_id: product.id,
            item_name: product.name,
        }));

        ReactGA.event('view_item_list', {
            items: items
        });
    }
}

const ProductGrid = ({ products, loading, loadingSize = 10, children, getDisplayProduct }) => {

    useEffect(() => trackListing(loading, products), [loading, products]);

    if(loading) {
        return (
            <div className="product-list">
                {range(loadingSize).map((e,i) =>
                    <ProductTileSkeleton
                        key={i}
                    />
                )}
                {children}
            </div>
        )
    }

    return (
        <div className="product-list">
            {products && products.map((product) =>
                <ProductDisplay
                    key={product.id}
                    product={product}
                    getDisplayProduct={getDisplayProduct}
                />
            )}
        </div>
    )
}

export default ProductGrid;
