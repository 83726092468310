import {useState} from "react";
import {api} from "../../../res/rest/api";
import {useSelector} from "react-redux";
import HttpClient from "../../../newStructure/services/http/HttpClient";

const usePrint = ({url, onError} = {}) => {

    const { session } = useSelector(state => state.local.auth);
    const [ printData, setPrintData ] = useState({
        loading: false,
    });

    const b64toBlob = (content, contentType) => {
        contentType = contentType || '';
        const sliceSize = 512;
        // method which converts base64 to binary
        const byteCharacters = window.atob(content);

        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {
            type: contentType
        });
    };

    const printPreview = (data, type = 'application/pdf') => {
        const blob = b64toBlob(data, type);
        const blobURL = URL.createObjectURL(blob);

        const iframe = document.createElement('iframe')
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function() {
            setTimeout(function() {
                iframe.focus();
                iframe.contentWindow.print();
            }, 1);
        };
    };

    const fetchAndPrintIt = () => {
        if(printData.loading) return;

        setPrintData({
            ...printData,
            loading: true
        })

        HttpClient.client.get(url, api.auth(session)).then(resp => {
            setPrintData({
                ...printData,
                loading: false
            })

            const data = resp.data.reportFile;
            printPreview(data);

        }).catch(e => {
            if(onError) {
                onError(e);
            }

            setPrintData({
                ...printData,
                loading: false,
                error: e,
            })

        });
    }

    return {
        loading: printData.loading,
        error: printData.error,
        fetchAndPrintIt,
        printPreview
    };

}

export default usePrint;
