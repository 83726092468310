import useSolrProduct from "./useSolrProduct";
import productSearchParamsBuilder from "../../../logic/product/productSearchParams";
import FilterUrl from "../../../logic/filter/filterUrl";
import MapUtil from "../../../logic/collection/mapUtil";
import useValueChangeListener from "../general/useValueChangeListener";

const useProductIDPage = (pageNo, productIDs, filterParam, pageSize=20) => {

    const {
        error,
        loading,
        products,
        productCount,
        getProducts,
    } = useSolrProduct();

    const fetchPage = () => {
        if(!productIDs || productIDs.length === 0) return

        const builder = productSearchParamsBuilder()
            .pageSize(pageSize)
            .pageNo(pageNo)
            .sortByID(true)
            .productIDs(productIDs);

        const filters = FilterUrl.getMap(filterParam);
        if(filters) {
            MapUtil.forEach(filters, (k, v) => {
                v.forEach(fv => builder.filterAttribute(k, fv))
            })
        }

        getProducts(builder.getParams());
    }

    useValueChangeListener(fetchPage, [pageNo, productIDs, filterParam, pageSize], []);

    const pageCount = (Math.ceil(productCount / pageSize)) + 1

    return {
        loading,
        error,
        products,
        pageCount
    }
}

export default useProductIDPage;
