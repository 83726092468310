import useAuthRequest from "../../../services/state/request/useAuthRequest";
import {useState} from "react";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {condition, request} from "../../../../res/rest/restRequest";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import {env} from "../../../../res/config/env";
import axios from "axios";
import {api} from "../../../../res/rest/api";
import FormItemCustom from "../../form/FormItemCustom";
import LoadingSwitcher from "../../general/loading/LoadingSwitcher";
import Calendar from "../../general/calendar/Calendar";

const BannerDateSelector = ({ categoryID, onChange }) => {

    const {
        loading,
        result,
        sendIt,
    } = useAuthRequest();

    const [[ startDate, endDate ], setDates ] = useState([]);

    useValueChangeListener(() => {
        if(categoryID > 0) {
            setDates([]);
            const req = request.model("x_Upsell_Product")
                .select("StartDate", "EndDate")
                .filter(condition.eq("M_Product_Category_ID", categoryID))
                .filter(condition.eq("isActive", true))
                .filter(condition.greaterThan("EndDate", "'" + idempiereDate.getDateString(new Date())) + "'");

            sendIt(async (session) => {
                const url = req.buildRequest(env.API_URL);
                const resp = await axios.get(url, api.auth(session));
                return resp.data.records
                    .filter(r => Boolean(r.StartDate) && Boolean(r.EndDate))
                    .map(r => [idempiereDate.getDate(r.StartDate), idempiereDate.getDate(r.EndDate)]);
            });
        }
    }, [categoryID], []);

    const onDateChange = (date, { interrupted, blocked, isInactive }) => {
        if(blocked || isInactive) return;
        if(!endDate && date > startDate && !interrupted) {
            setDates([startDate, date]);
            onChange({ startDate, endDate : date });
        } else {
            onChange({ startDate : date });
            setDates([date]);
        }
    };

    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 3);

    return (
        <FormItemCustom
            gridColumn="span 2"
            name="Display Duration"
            description="Select the date range for your banner to be shown on the site."
            onChange={onChange}
        >
            <div style={{marginTop: 10}}>
                <LoadingSwitcher loading={loading}>
                    <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        startDate={startDate}
                        endDate={endDate}
                        onDateClick={onDateChange}
                        blockedOut={result}
                    />
                </LoadingSwitcher>
            </div>
        </FormItemCustom>
    )
}

export default BannerDateSelector;
