import useAuthRequest from "../../../services/state/request/useAuthRequest";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {condition, request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import axios from "axios";
import {api} from "../../../../res/rest/api";
import Form from "../../form/Form";
import FormHeading from "../../form/FormHeading";
import LoadingSwitcher from "../../general/loading/LoadingSwitcher";
import FormItem from "../../form/FormItem";

const BannerFormTotal = ({ dates }) => {

    const daysBetween = (start, end) => Math.ceil((end - start) / (24 * 60 * 60 * 1000)) + 1

    const {
        loading,
        result : charge,
        sendIt
    } = useAuthRequest();

    useValueChangeListener(() => {
        const req = request.model("c_charge")
            .select("chargeamt")
            .filter(condition.eq("c_charge_ID", 1000205))
            .buildRequest(env.API_URL);
        const getIt = async (s) => {
            const resp = await axios.get(req, api.auth(s));
            return resp?.data?.records?.[0]?.ChargeAmt;
        }
        sendIt(getIt);
    }, [2], [])

    return (
        <Form>
            <FormHeading gridColumn="span 2">Total</FormHeading>
            <LoadingSwitcher loading={loading}>
                <FormItem gridColumn="span 2">
                    {dates && dates.startDate && dates.endDate ?
                        <>
                            {charge ?
                                <p className="input-form-text" style={{fontWeight: 500, fontSize: 22}}>
                                    £{charge * daysBetween(dates.startDate, dates.endDate)}.00
                                </p> :
                                <p className="input-form-text">
                                    Something has gone wrong, we have not been able to calculate your total.
                                </p>
                            }
                        </> :
                        <p className="input-form-text">
                            Please select a date range.
                        </p>
                    }
                </FormItem>
            </LoadingSwitcher>
        </Form>
    )
}

export default BannerFormTotal;
