import {formatDisplayPrice} from "../../../../../../res/dataServices/pricing";
import errorReportRequest from "../../../../../services/rest/request/error/errorReportRequest";

const reportUnrecognisedCartMessage = async (svrMessage) => {
    void errorReportRequest()
        .tag("Unrecognised Checkout Error Message")
        .message(svrMessage)
        .url("checkout")
        .sendIt(1);
}

const getCartErrorMessage = (cart) => {

    if(cart.error.prepare) {

        try {
            void reportUnrecognisedCartMessage(cart.error.prepare);
        } catch (e) {}

        const message = cart.error.prepare.includes("Minimum Order Value Not Reached") ? cart.error.prepare :
            "An issue occurred preparing your order, message: " + cart.error.prepare

        return [ "Order Error", message ];
    }

    if(cart.error.checkout) {

        try {
            void reportUnrecognisedCartMessage(cart.error.checkout);
        } catch (e) {}

        const message = "An issue has occurred preparing your order. " +
            "The message from the server is:\n " + cart.error.checkout + ". If this problem persists, please get" +
            " in contact with our team! "
        return [ "Complete Error", message ];
    }
}

const getCartFunctions = (cart) => {
    const functions = {};

    functions.isCartEmpty = () => {
        return !cart?.loadingCart && cart.lines && Object.keys(cart.lines).length === 0;
    }
    functions.isOrderComplete = () => {
        return !cart?.loadingCart && cart.order && cart.order.DocStatus.id === 'CO';
    }
    functions.isCartLoading = () => {
        return cart.loadingCart;
    }
    functions.isOrderLoading = () => {
        return cart.loadingOrder;
    }
    functions.isCheckoutLoading = () => {
        return (cart.loading.prepare || cart.loading.checkout);
    }
    functions.isOrderDetailThere = () => {
        return Boolean(cart.order);
    }

    functions.getOrderID = () => {
        return cart?.id;
    }
    functions.getDocumentNo = () => {
        return cart?.order?.DocumentNo
    }
    functions.getReference = () => {
        return cart?.order?.POReference;
    }
    functions.getPromotionCode = () => {
        return cart?.order?.PromotionCode;
    }
    functions.getBPartner = () => {
        return cart?.order?.C_BPartner_ID;
    }
    functions.getBPartnerID = () => {
        return functions.getBPartner().id;
    }
    functions.getBPartnerLocation = () => {
        return cart?.order?.C_BPartner_Location_ID;
    }
    functions.getBPartnerLocationID = () => {
        return functions.getBPartnerLocation()?.id;
    }
    functions.getDeliveryViaRule = () => {
        return cart?.order?.DeliveryViaRule?.id;
    }
    functions.getDatePromised = () => {
        return cart?.order?.DatePromised ? new Date(cart.order.DatePromised) : null;
    }
    functions.getPaymentType = () => {
        return cart?.order?.PaymentRule?.id;
    }
    functions.getDateOrdered = () => {
        return cart?.order?.DateOrdered ? new Date(cart.order.DateOrdered) : null;
    }

    functions.getGuidePrice = () => {
        return formatDisplayPrice(cart?.getProductTotal());
    }

    functions.getCheckoutError = () => {
        return getCartErrorMessage(cart);
    }

    return functions;
}

export default getCartFunctions;
