
const defaultClient = {

    get: function() { throw new Error("Http Client Not Set"); },
    put: function() { throw new Error("Http Client Not Set"); },
    post: function() { throw new Error("Http Client Not Set"); },
    delete: function() { throw new Error("Http Client Not Set"); },

}

const auth = (token) => {
    if(!token) {return {headers: {}};}

    return {
        headers: {
            Authorization: `Bearer ` + token
        }
    }
}

const HttpClient = {
    client: defaultClient,
    auth
}

export default HttpClient;
