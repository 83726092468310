import useUsuals from "../../../services/state/product/useUsuals";
import {useMemo} from "react";
import useProductIDPage from "../../../services/state/product/useProductIDPage";

const getAllProductIDs = (usuals) => {
    return Object.values(usuals)
        .flat(1)
        .filter(i => i.M_Product_ID != null)
        .sort((a,b) => b.purchase_instance_count - a.purchase_instance_count)
        .map(p => p.M_Product_ID.id);
}

const useUsualsPage = (pageNo, category, filter, pageSize) => {

    const {
        loading,
        usuals
    } = useUsuals();

    const productIDs = useMemo(() => {
        if(!usuals) return;
        if(category) return usuals[category]?.map(p => p.M_Product_ID.id);
        return getAllProductIDs(usuals);
    }, [usuals, category]);

    const {
        loading: loadingProducts,
        products,
        pageCount
    } = useProductIDPage(pageNo, productIDs, filter, pageSize);

    return {
        loading: loadingProducts || loading,
        pageCount,
        products,
        usuals
    }

}

export default useUsualsPage;
