import {condition, request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import HttpClient from "../../../http/HttpClient";

const bankingRequest = () => {

    const req = request.model(api.MODEL.BANK_ACCOUNT)
        .expand(request.subQuery("x_mandate"))
        .filter(condition.eq("isActive", "true"));

    const builder = {};

    builder.sendIt = async (session) => {
        req.filter(condition.eq("c_bpartner_ID", session.bPartnerID));
        const url = req.buildRequest(env.API_URL);
        const response = await HttpClient.client.get(url, api.auth(session));
        return response.data.records;
    }

    return builder;
}

export default bankingRequest;
