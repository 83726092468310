import {createAsyncThunk} from "@reduxjs/toolkit";
import {env} from "../../res/config/env";
import {condition, request} from "../../res/rest/restRequest";
import {api} from "../../res/rest/api";
import ReactGA from "react-ga4";
import attemptLoginRefresh from "../util/refreshLoginController";
import HttpClient from "../../newStructure/services/http/HttpClient";

export const API_LOGIN = "/api/v1/auth/tokens";
export const API_REFRESH = '/api/v1/auth/refresh'
export const API_BP = "/api/v1/models/ad_user/";
export const API_AUTH = "/api/v1/auth/";

const AUTH_ENDPOINT = `${env.API_URL}${API_AUTH}`

const trackEvent = (action, label) => {
    ReactGA.event({category : "login", action, label});
}

let logInRun = false;
const trackLogIn = (refresh) => {
    if(logInRun) return;

    const logInType = refresh ? "Refreshed Token" : "Auth Log In"
    trackEvent("First Log In For Session", logInType);

    logInRun = true;
}

const multiStepLogin = () => {
    const loginData = (userName, password) => {
        return {
            userName: userName,
            password: password,
        }
    }

    const sessionData = (orgID, warehouseID) => {
        return {
            clientId: env.AD_CLIENT_ID,
            roleId: env.AD_ROLE_ID,
            organizationId: orgID,
            warehouseId: warehouseID,
            language: env.LANGUAGE
        }
    }

    // const roles = (clientID) => `${AUTH_ENDPOINT}roles?client=${clientID}`
    const organisations = (clientID, roleID) => `${AUTH_ENDPOINT}organizations?client=${clientID}&role=${roleID}`
    const warehouses = (clientID, roleID, organizationID) =>
        `${AUTH_ENDPOINT}warehouses?client=${clientID}&role=${roleID}&organization=${organizationID}`
    // const languages = (clientID) => `${AUTH_ENDPOINT}language?client=${clientID}`

    // const authoriseUser = async (userName, password, captchaToken) => {
    //     const authUrl = env.API_URL + "/api/v1/temp/auth";
    //     const dataToLogin = { userName, password, captchaToken };
    //     return await HttpClient.client.post(authUrl, dataToLogin, {'Content-Type': 'application/json'});
    // }

    const authoriseUser = async (userName, password, captchaToken) => {
        const authUrl = AUTH_ENDPOINT + "tokens";
        const dataToLogin = loginData(userName, password);
        const config = {
            headers: { captchaToken, userName },
        };
        return HttpClient.client.post(authUrl, dataToLogin, config);
    }

    const getUserOrganisations = async (token) => {
        const url = organisations(env.AD_CLIENT_ID, env.AD_ROLE_ID);
        const orgResponse = await HttpClient.client.get(url, auth(token));
        return orgResponse.data.organizations.filter(org => org.id !== 0);
    }

    const getOrgWarehouse = async (token, orgID) => {
        const url = warehouses(env.AD_CLIENT_ID, env.AD_ROLE_ID, orgID);
        const warehouseResponse = await HttpClient.client.get(url, auth(token));
        const theWarehouse = warehouseResponse.data.warehouses[0];
        return theWarehouse.id;
    }

    const runInitialStep = async (userName, password, captchaToken) => {
        const authResponse = await authoriseUser(userName, password, captchaToken);
        return authResponse.data.token;
    }

    const completeLogIn = async (token, orgID, warehouseID) => {
        const data = sessionData(orgID, warehouseID);
        const url = AUTH_ENDPOINT + "tokens"
        const authResponse = await HttpClient.client.put(url, data, auth(token));
        return authResponse.data;
    }


    return  {
        runInitialStep,
        getUserOrganisations,
        getOrgWarehouse,
        completeLogIn
    }

}

const auth = (authToken) => {
    return {
        headers: {
            Authorization: `Bearer ` + authToken
        }
    };
}

const getUserInfo = async (authToken, userID) => {

    const userReq = request.model(api.MODEL.AD_USER)
        .select("name", "value", "c_bpartner_ID", "email")
        .filter(condition.eq("ad_user_ID", userID))
        .hostURL(env.API_URL)
        .buildRequest();

    const userResp = await HttpClient.client.get(userReq, auth(authToken));
    const user = userResp.data.records[0];

    return {
        userName: user.Name,
        userCode: user.Value,
        email: user.EMail
    };
}

const getAccessibleBPartners = async (authToken, userEmail) => {

    const req = request.model(api.MODEL.AD_USER)
        .select("c_bpartner_ID")
        .expand(request.subQueryList("c_bpartner_ID")
            .select("value","name", "X_Allow_Back_Order", "IsCustomer", "IsVendor", "IsActive", "M_PriceList_ID"))
        .filter(condition.eq("email", `'${userEmail}'`), condition.eq("isActive", "true"))
        .hostURL(env.API_URL)
        .buildRequest();

    const bps = {};
    const bpsResponse = await HttpClient.client.get(req, auth(authToken));
    const returnedBPs = bpsResponse.data.records;
    returnedBPs.forEach(u => {
        if(u.C_BPartner_ID && (u.C_BPartner_ID.IsCustomer || u.C_BPartner_ID.IsVendor) && u.C_BPartner_ID.IsActive)
            bps[u.C_BPartner_ID.id] = u.C_BPartner_ID
    });
    return Object.values(bps)
}

// const getOrgName = (orgID, token) => {
//
//     const req = request.model(api.MODEL.AD_ORG)
//         .filter(condition.eq("ad_org_ID", orgID))
//         .hostURL(env.API_URL)
//         .buildRequest();
//     const result = HttpClient.client.get(req, auth(token));
//     return result;
// }

export const login = createAsyncThunk(
    'auth/auth',
    async ({userName, password, authToken: passedToken, orgID: passedOrg, captchaToken }, { getState, rejectWithValue })  => {
        try {
            trackLogIn(false);
            const track = (label) => trackEvent("login-attempt", label);
            track("logging in " + userName);

            const authInfo = {
                orgID: passedOrg,
                authToken: passedToken,
                orgs: getState().local.auth.orgSelect?.orgs
            }

            const auth = multiStepLogin();

            if(!passedOrg) {
                authInfo.authToken = await auth.runInitialStep(userName, password, captchaToken);
                const orgs = await auth.getUserOrganisations(authInfo.authToken);
                if(orgs.length === 1) {
                    authInfo.orgID = orgs[0].id;
                    authInfo.orgs = orgs;
                } else {
                    return { authToken : authInfo.authToken, orgs };
                }
            }

            const orgName = authInfo.orgs.length > 1 ?
                authInfo.orgs.find(org => org.id === authInfo.orgID).name :
                authInfo.orgs[0].name;

            const warehouseID = await auth.getOrgWarehouse(authInfo.authToken, authInfo.orgID);
            const loginResponse = await auth.completeLogIn(authInfo.authToken, authInfo.orgID, warehouseID);
            const userID = loginResponse.userId;
            const authToken = loginResponse.token;
            const refreshToken = loginResponse.refresh_token;

            track("fetching user info");
            const userInfo = await getUserInfo(authToken, userID);
            track("user info response: " + JSON.stringify(userInfo));

            track("Getting available bps");
            const accessibleBPs = await getAccessibleBPartners(authToken, userInfo.email);
            track("available bp response: " + JSON.stringify(accessibleBPs));
            const bpInfo = {};
            if(accessibleBPs.length === 1) {
                const bp = accessibleBPs[0];
                bpInfo.bPartnerID = bp.id;
                bpInfo.businessName = bp.Name;
                bpInfo.businessCode = bp.Value;
                bpInfo.canBackOrder = bp["X_Allow_Back_Order"];
                bpInfo.priceListID = bp["M_PriceList_ID"]?.id
            }

            track("Response");
            return {
                ...userInfo,
                ...bpInfo,
                authToken,
                refreshToken,
                userID,
                accessibleBPs,
                orgName,
                orgID: authInfo.orgID
            }
        } catch (error) {
            trackEvent("Failed-Login", "Login error: " + JSON.stringify(error));
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


export const refreshLogin = createAsyncThunk(
    'auth/refresh',
    async (notUsed, { getState, rejectWithValue, dispatch }) => {
        try {
            trackLogIn(true);

            const session = getState().local.auth?.session;

            const refresh_token = session?.refreshToken;
            const userId = session?.userID;
            const clientId = env.AD_CLIENT_ID;

            if(refresh_token) {
                attemptLoginRefresh({refresh_token, userId, clientId}, dispatch);
            } else {
                throw new Error("No refresh token");
            }

        } catch (error) {
            trackEvent("Failed-Refresh", "Login error: " + JSON.stringify(error));
            if (error.response && error.response?.data?.message) {
                return rejectWithValue(error.response.data.message);
            } else if(error?.message) {
                return rejectWithValue(error.message);
            } else {
                return rejectWithValue(error);
            }
        }
    }
)
