import ProductInput from "../../../component/product/ProductInput";
import BinIcon from "../../../../res/icon/BinIcon";
import React, {useState} from "react";
import './productLine.css'
import useSession from "../../../services/state/session/useSession";

const ProductLineInput = ({ product }) => {

    const deleteLine = () => {
        product.setQuantityEntered(0);
    }

    return (
        <div className="product-line-input">
            <ProductInput
                product={product}
                includeDeleteButton
            />
            <div className="product-line-delete" onClick={deleteLine}>
                <BinIcon />
            </div>
        </div>
    )
}

const Image = ({ product }) => {

    const [imgLoading, setImgLoading] = useState(true);
    const stopImgLoading = () => {
        setImgLoading(false);
    }

    return (
        <div className={`product-line-img-container ${imgLoading ? 'loading' : ''}`}>
            <img
                src={product.getImageUrl()}
                alt="Product"
                onLoad={stopImgLoading}
                onError={stopImgLoading}
            />
        </div>
    )

}


const PriceDisplay = ({ product }) => {

    const isPriceMissing = isNaN(product.getDisplayPrice());
    const isLoadingPrice = product.isLoadingPurchaseInfo();
    const prePromotionPrice = product.getPromotionPrice();

    if(isPriceMissing && isLoadingPrice) {
        return <p style={{ marginTop: "20px" }} className='roduct-line-text-container prod-price loading'>&nbsp;</p>
    } else {
        return (
            <div className="product-line-text-container">
                <p className='product-line-price'>
                    {prePromotionPrice &&
                        <>
                            <span className="product-line-price-mark-down">
                                {prePromotionPrice}
                            </span>
                            {' '}
                        </>
                    }
                    {product.getDisplayPrice()}
                </p>
            </div>
        )
    }

}

const Tag = ({ product }) => {

    const highlightText = product.getLabel();

    const getTagColour = () => {
        if(product.getBuyingPromotion()?.length > 0) {
            return "var(--primary)";
        } else if(highlightText === "Promotion") {
            return "var(--primary)";
        } else if(highlightText === "Out Of Stock") {
            return "var(--inactive)";
        } else if(highlightText) {
            return "var(--secondary)";
        }
    }

    return (
        <div style={{background: getTagColour()}} className="product-line-tag">
            {highlightText}
        </div>
    )

}

const ProductControl = ({ product }) => {

    return (
        <>
            <PriceDisplay product={product} />
            <div className="product-line-text-container product-line-input-container">
                <ProductLineInput product={product}/>
            </div>
        </>
    )
}

const SignInMessage = () => {
    const { isSignedIn, isCustomer } = useSession();
    const message = isSignedIn && !isCustomer ? "You are currently not a customer" : "Please sign in to see prices"

    return (
        <div className="product-line-sign-in-message product-line-input-container">
            <p>{message}</p>
        </div>
    )
}

const SessionSwitcher = ({ product }) => {
    const { isSignedIn, isCustomer } = useSession();
    const showPrices = !product.isBrochure() && isSignedIn && isCustomer;
    if(showPrices) {
        return <ProductControl product={product} />
    } else {
        return <SignInMessage />
    }
}

const ProductLine = ({ product }) => {

    return (
        <div className="product-line-grid">
            <Image product={product}/>
            <div className="product-line-text-container">
                <p className="product-line-code product-h-l">{product.getValue()}</p>
                <p className="product-line-name product-h-l">{product.getName()}</p>
                <p className="product-line-case product-h-l">{product.getCaseDescription()}</p>
            </div>
            <Tag product={product} />
            <SessionSwitcher product={product} />
        </div>
    )
}

export default ProductLine;
